import React, { useEffect, useRef, useState } from "react";
import AuthMain from "./AuthMain/AuthMain";
import logo from "./logo.svg";
import Main from "./Main/Main";
import usePasswordHandler from "./Hooks/usePasswordHandler";
const { REACT_APP_PASSWORD } = process.env;
const passwordLength: number = REACT_APP_PASSWORD?.length ?? 0;
export default function App() {
  const { isAuthenticated } = usePasswordHandler();

  return isAuthenticated ? <AuthMain /> : <Main />;
}
