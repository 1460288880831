import React from "react";

export type Props = {
  id: string;
  gradientColors: string[] | string;
  gradientUnits?: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  opacity?: number;
};

export default function SvgLinearGradientFill({
  id,
  gradientColors,
  gradientUnits,
  x1,
  y1,
  x2,
  y2,
  opacity,
}: Props) {
  const colors = Array.isArray(gradientColors)
    ? gradientColors
    : [gradientColors, gradientColors];
  return (
    <linearGradient
      id={id}
      gradientUnits={gradientUnits ?? "objectBoundingBox"}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
    >
      {colors?.map((color: string, index: number) => {
        return (
          <stop
            key={index}
            stopOpacity={opacity ?? 1}
            offset={gradientColors?.length ? index / gradientColors.length : 0}
            stopColor={color}
          />
        );
      })}
    </linearGradient>
  );
}
