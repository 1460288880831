import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
export type GeolocationData = {
  ip: string;
  country: string;
  city: string;
  latitude: number;
  longitude: number;
};
export default function useIpGeolocation() {
  const [geolocationData, setGeolocationData] = useState<GeolocationData>();

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    const data = res.data;
    setGeolocationData({
      ip: data.IPv4,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    geolocationData,
  };
}
