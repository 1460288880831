import firebase from "../firebase";
import "firebase/firestore";
const firestore = firebase.firestore();
const firestoreConversation = (ip: string) => {
  return firestore.collection("conversations").doc(ip);
};
const firestoreConversationMessages = (ip: string) => {
  return firestore.collection("conversations").doc(ip).collection("messages");
};

export { firestoreConversation, firestoreConversationMessages };
