const white = "#FFFFFF";
const black = "#000000";
const opacity = (color?: string, opacity?: number) => {
  return `${color ?? "#FFFFFF"}${((opacity ?? 1) * 255)
    .toString(16)
    .substr(0, 2)}`;
};
const orangeToPurple = [
  "#ffb56b",
  "#f89d63",
  "#ef865e",
  "#e36e5c",
  "#d5585c",
  "#c5415d",
  "#b22c5e",
  "#9c155f",
  "#830060",
  "#680060",
  "#48005f",
  "#1f005c",
];
export { white, black, opacity, orangeToPurple };
