import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import SvgLinearGradientFill from "../../Components/SvgLinearGradientFill";
import useIpGeolocation from "../../Hooks/useIpGeolocation";
import {
  sendChatIn,
  setupChat,
} from "../../firebase/firestore/useFirestoreActions";
import { opacity, orangeToPurple, white } from "../../Hooks/useColors";
export default function Chat() {
  const [ip, setIp] = useState<string>("anon");
  const { geolocationData } = useIpGeolocation();
  const handleSendChat = () => {
    const element = document.getElementById("text") as HTMLInputElement;
    const text = element.value;
    element.value = "";
    if (!text.length) return;
    const data = {
      text: text,
    };
    sendChatIn(ip, data);
  };
  useEffect(() => {
    const ip = geolocationData?.ip;
    if (ip) {
      setupChat(geolocationData!);
      setIp(ip);
    }
  }, [geolocationData]);
  return (
    <div
      style={{
        height: 500,
        width: 400,
        borderRadius: 20,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <svg viewBox="0 0 400 500">
        <SvgLinearGradientFill
          id="gradient"
          gradientColors={orangeToPurple}
          x1={0}
          y1={0}
          x2={1}
          y2={1}
        />
        <rect width={400} height={500} fill={"url(#gradient)"} />
      </svg>
      <div
        style={{
          height: 490,
          width: 390,
          position: "absolute",
          top: 5,
          left: 5,
          borderRadius: 18,
          backgroundColor: opacity(white, 0.8),
        }}
      >
        <input id="text" />
        <div
          style={{
            height: 10,
            width: 10,
            top: 5,
            left: 5,
            borderRadius: 2,
            backgroundColor: "blue",
          }}
          onClick={handleSendChat}
        ></div>
      </div>
    </div>
  );
}
