import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
const { REACT_APP_PASSWORD } = process.env;
const passwordLength: number = REACT_APP_PASSWORD?.length ?? 0;
export default function usePasswordInput() {
  var currentInput: string = "";
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleKeyPressEvent = (keypressEvent: KeyboardEvent) => {
    const key: string = keypressEvent.key;
    currentInput = (currentInput + key).substr(-passwordLength);
    if (currentInput.length < passwordLength) return;
    if (currentInput === REACT_APP_PASSWORD) {
      setIsAuthenticated(true);
    }
  };
  const handleKeyUpEvent = (keypressEvent: KeyboardEvent) => {
    const key: string = keypressEvent.key;
    if (key === "Escape") setIsAuthenticated(false);
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPressEvent);
    window.addEventListener("keyup", handleKeyUpEvent);
    return () => {
      window.removeEventListener("keypress", handleKeyPressEvent);
      window.removeEventListener("keyup", handleKeyUpEvent);
    };
  }, []);

  return {
    isAuthenticated,
  };
}
