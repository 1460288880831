import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
export default function AuthMain() {
  return (
    <view
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: "green",
      }}
    >
      {/* <Main /> */}
    </view>
  );
}
