import { GeolocationData } from "../../Hooks/useIpGeolocation";
import firebase from "../firebase";
import {
  firestoreConversation,
  firestoreConversationMessages,
} from "./useFirestorePaths";
const setupChat = (data: GeolocationData) => {
  firestoreConversation(data.ip).update(data);
};
const sendChatIn = (ip: string, data: ChatData) => {
  firestoreConversation(ip).update({
    lastMessage: data,
  });
  firestoreConversationMessages(ip).add({
    ...{ createdAt: firebase.firestore.FieldValue.serverTimestamp(), in: true },
    ...data,
  });
};
export { setupChat, sendChatIn };
