import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBW3i4x-kRzNhYAJkJuKlPmPFlx-7cpqMI",
  authDomain: "ethan-pletcher.firebaseapp.com",
  projectId: "ethan-pletcher",
  storageBucket: "ethan-pletcher.appspot.com",
  messagingSenderId: "395835311368",
  appId: "1:395835311368:web:4975740e4143b1cf9e98bd",
  measurementId: "G-JSCXF24WN6",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
