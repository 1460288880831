import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import Chat from "./Chat/Chat";
import firebase from "../firebase/firebase";
import "firebase";
const pushTry = () => {
  firebase.firestore().collection("HI").add({
    title: "todo",
    complete: false,
  });
};
export default function Main() {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Chat />
      {/* <div
        style={{ height: 50, width: 200, backgroundColor: "red" }}
        onClick={pushTry}
      /> */}
      {/* <WeatherHeader /> */}
    </div>
  );
}
